<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Collapse from "./components/Collapse.vue";
export default {
  components: {
    Collapse,
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

</style>
