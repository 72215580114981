import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login/LoginView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    children: [
      {
        path: "/about/home",
        component: HomeView,
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/userlist',
        name: 'userlist',
        component: () => import('../views/UserManagement/UserList.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/Listofgoods',
        name: 'Listofgoods',
        component: () => import('../views/Order/Listofgoods.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/statistics',
        name: 'statistics',
        component: () => import('../views/Order/statistics.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/liebiao',
        name: 'lie',
        component: () => import('../views/Commodity/Commodityliebiao.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/control',
        name: 'control',
        component: () => import('../views/Commodity/control.vue'),
        meta: {
          isShow: true
        },
      },
      // 远程控制
      {
        path: '/about/remoteControl',
        name: 'remoteControl',
        component: () => import('../views/Commodity/remoteControl.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/regulate',
        name: 'regulate',
        component: () => import('../views/Commodity/regulate.vue'),
        meta: {
          isShow: true
        },
      },
      {//地图
        path: '/about/ditu',
        name: 'ditu',
        component: () => import('../views/DataStatistics/dituzujian.vue'),
        meta: {
          isShow: true
        },
      },
      {//权限管理中得权限列表
        path: '/about/ListView',
        name: 'ListView',
        component: () => import('../views/Permission/PermissionListView.vue'),
        meta: {
          isShow: true
        },
      },
      {//权限管理中得权限列表
        path: '/about/trajectory',
        name: 'trajectory',
        component: () => import('../views/Permission/trajectory.vue'),
        meta: {
          isShow: true
        },
      },
      // 限管理中的角色列表
      {
        path: '/about/role',
        name: 'role',
        component: () => import('../views/Permission/RoleList.vue'),
      },
      {
        path: '/about/Listofgoods',
        name: 'Listofgoods',
        component: () => import('../views/Order/Listofgoods.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/classify',
        name: 'classify',
        component: () => import('../views/classify/classifyView.vue'),
        meta: {
          isShow: true
        },
      },
      {
        path: '/about/battery',
        name: 'battery',
        component: () => import('../views/battery/battery.vue'),
        meta: {
          isShow: true
        },
      }
    ],
  },
]
const router = new VueRouter({
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(item => item.meta.isShow)) {
//     if (!store.state.isLogin) {
//       next('about/battery')
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && store.state.isLogin) {
    const token = localStorage.getItem('token');
    if (token) {
      store.commit('setLogin', token);
      next('/about/battery'); // 跳转到首页
    } else {
      next('/'); // 跳转到登录页
    }
  } else {
    next(); // 继续路由跳转
  }
});

export default router
