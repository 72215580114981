import  $ from '@/utils/requset'
// 登录
export function login(u, p) {
    const data = {
        u ,
      p,
    }
    return $({
      url: 'login/',
      method: 'get',
      params: data
    })
  }
//电池列表
  export function getDeviceList(o) {
    return $({
      url: 'get_device_list/',
      method: 'get',
      params: o
    })
  }
  //BMS状态
  export function getBmsState(id) {
    return $({
      url: 'get_bms_state/',
      method: 'get',
      params: {id:id}
    })
  }

   //BMS信息
   export function getBmsBaseInfo(id) {
    return $({
      url: 'get_bms_base_info/',
      method: 'get',
      params: {id:id}
    })
  }
   //设置BMS信息
   export function setBmsBaseInfo(bmsParams) {
    return $({
        url: 'set_bms_base_info',
        method: 'post',
        data: bmsParams,
    });
}

  //BMS参数
  export function getBmsInfo(id,tp) {
    const data = {
      id,
      tp,
    }
    return $({
      url: 'get_bms_info/',
      method: 'get',
      params: data
    })
  }

   //设置BMS参数
   export function setBmsInfo(id, tp,bmsParams) {
    return $({
        url: `set_bms_info/?id=${id}&tp=${tp}`,
        method: 'post',
        data: bmsParams,
    });
}



   //预警信息
   export function getAlarmList(id) {
    return $({
      url: 'get_alarm_list/',
      method: 'get',
      params: {id:id}
    })
  }

  //统计信息
  export function getReportInfo(id) {
    return $({
      url: 'get_report_info/',
      method: 'get',
      params: {id:id}
    })
  }
  //服务配置
  export function setDeviceService(deviceNo , flag , value ) {
    return $({
      url: 'set_device_service',
      method: 'post',
      data: {
        deviceNo: deviceNo,
        flag: flag,
        value: value
      }
    });
  }
  //配置信息
  export function getDeviceArgs(id) {
    return $({
      url: 'get_device_args/',
      method: 'get',
      params: {id:id}
    })
  }

  //电子围栏
  export function getGpsFence(id) {
    return $({
      url: 'get_gps_fence/',
      method: 'get',
      params: {id:id}
    })
  }

  // 创建电子围栏
export function createFence(name, deviceNo, positions) {
  return $({
    url: 'create_fence',
    method: 'post',
    data: {
      name: name,
      deviceNo: deviceNo,
      positions: positions
    }
  });
}
 //删除电子围栏
 export function delGpsFence(id,fn) {
  return $({
    url: 'del_gps_fence/',
    method: 'get',
    params: {id:id,fn:fn}
  })
}

 // 获取MCU
 export function getMcuInfo(id) {
  return $({
    url: 'get_mcu_info/',
    method: 'get',
    params: {id:id}
  });
}

  // 设置MCU
  export function setMcuInfo(mcuData) {
    return $({
      url: 'set_mcu_info',
      method: 'post',
      data: mcuData
    });
  }

  // 获取定位
  export function getCurrentInfo(id) {
    return $({
      url: 'get_current_info/',
      method: 'get',
      params: {id:id}
    });
  }

   // 获取轨迹
  export function getGpsRecord(id,st,et) {
    const data = {
      id,
      st,
      et
    }
    return $({
      url: 'get_gps_record/',
      method: 'get',
      params: data
    })
  }
   // 远程控制
   export function setDeviceRemote(deviceNo ,flag ,value ) {
    return $({
      url: 'set_device_remote',
      method: 'post',
      data: {
        deviceNo:deviceNo,
        flag:flag,
        value:value
      }
    })
  }
  
   // 同步数据
   export function pullDeviceData(id,tp) {
    const data = {
      id,
      tp
    }
    return $({
      url: 'pull_device_data/',
      method: 'get',
      params: data
    })
  }
