<template>
  <div class="HelloFlying">
    <div ref="radar" style="width:100%; height:400px;"></div>
  </div>
</template>

<script>
export default {
  name: 'pieChart',
  data(){
    return{
      
    };
  },
  props: {
    // InstrumentPane: Object,
  },
  mounted(){
    window.addEventListener('resize', this.handleResize); 
      this.drawLine();
    },
  beforeDestroy() { //销毁
    if (this.chart) {
      this.chart.dispose();
    }
  },

  methods:{
    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    drawLine() {
         // 基于准备好的dom，初始化echarts实例
        let myCharts = this.$echarts.init(this.$refs.radar);
            window.addEventListener('resize', function () {  
            // 重绘图表  
            myCharts.resize();  
        });
        const option = {
            // tooltip: {
            //     trigger: 'item'
            // },
            legend: {
                top: '85%',
                left: 'center',
                textStyle: {
                  color: '#fff', // 设置图例字体颜色为白色
                  fontSize:14
              }
            },
            series: [
                {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    color: '#fff' ,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: 16,
                    // fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 6, name: '已到期' },
                    { value: 9, name: '30天内到期' },
                    { value: 0, name: '7天内到期' },
                ]
                }
            ]
            };
      option && myCharts.setOption(option);
    }

  }
}
</script>

<style scoped lang="scss">
.HelloFlying{
  width: 100%;
  height: 90%;
  margin-top:-50px;
}

</style>