<template>
<div>
   <div id="china_map_box">
      <div id="china_map" ></div>
  </div>
  <div>
    <el-dialog
      title="设备分布"
      :visible.sync="distribution"
      width="width"
      :before-close="distrItem">
      <div class="dis_box" style="font-size: 18px;">
        <div>
          <el-select v-model="dataListMane.name">
            <el-option
              v-for="item in dataList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select> 
          <span>总共： {{dataListMane.value}}个</span>  
        </div>
        <div>
          <el-button style="background-color: #00CED1;" type="primary" size="medium">导出全部设备</el-button>
        </div>
      </div>
      <el-table  element-loading-background="rgba(0, 0, 0, 0.8)" class="custom-table" :header-cell-style="{ 'text-align': 'center',}" :data="dataListMane" style="width: 100%" >
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column  prop="name" label="地区">
        </el-table-column>
        <el-table-column  prop="value" label="设备数量(个)">
        </el-table-column>
        <el-table-column  prop="email" label="占比">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="203">
            <button @click="customer(item)" class="box_BMS"  >
              </button>
        </el-table-column>
    </el-table>
    </el-dialog>
  </div>
</div>
 
</template>

<script>
import echarts from "echarts";
import 'echarts/map/js/china.js' 
export default {
  data() {
    return { 
      distribution:false,
      dataListMane:[
        {
          name:'',
          value:'',
        }
      ],
      options: {
        tooltip: {
          triggerOn: "mousemove",   //mousemove、click
          padding:8,
          borderWidth:1,
          borderColor:'#409eff',
          backgroundColor:'rgba(255,255,255,0.7)',
          textStyle:{
            color:'#000000',
            fontSize:13
          },
          formatter: function(e, t, n) {
            let data = e.data;
            //模拟数据
            data.specialImportant = Math.random()*1000 | 0;
            data.import = Math.random()*1000 | 0;
            data.compare = Math.random()*1000 | 0;
            data.common = Math.random()*1000 | 0;
            data.specail = Math.random()*1000 | 0;

            let context = `
               <div >
                   <p><b style="font-size:15px;">${data.name}</p>
                   <p class="tooltip_style"><span class="tooltip_right">${data.value}</span></p>
               </div>
            `
            return context;
          }
        },
        visualMap: {
          show:true,
          left: 26,
          bottom: 40,
          showLabel:true,
          textStyle: { // 添加 textStyle 对象  
            color: '#fff' // 设置 label 字体颜色  
          },
          pieces: [
            {
              gte: 5,
              label: "> 5",
              color: "#2ec7c9"
            },
            {
              gte: 4,
              lt: 5,
              label: "4 - 5",
              color: "#53cfd1"
            },
            {
              gte: 2,
              lt:4,
              label: "2 - 4",
              color: "#78d7d8"
            },
            {
              gte: 1,
              lt: 2,
              label: "1 - 2",
              color: "#9ddfe0"
            },
            {
              gte: 0,
              lt: 1,
              label:'0 - 1',
              color: "#c2e7e7"
            }
          ]
        },
        geo: {
          map: "china",
          scaleLimit: {
            min: 1,
            max: 2
          },
          zoom: 1,
          top: 120,
          label: {
            normal: {
              show:true,
              fontSize: "14",
              color: "rgba(0,0,0,0.7)"
            }
          },
          itemStyle: {
            normal: {
              //shadowBlur: 50,
              //shadowColor: 'rgba(0, 0, 0, 0.2)',
              borderColor: "rgba(0, 0, 0, 0.2)"
            },
            emphasis: {
              areaColor: "#f2d5ad",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 0
            }
          }
        },
        series: [
          {
            name: "突发事件",
            type: "map",
            geoIndex: 0,
            data:[],
            emphasis: {
            label: {
                show: true
            }
        },
        selectedMode: 'single', // 设置为单选模式
        data: this.dataList
          }
        ]
      },
      //echart data
      dataList: [
        {
          name: "南海诸岛",
          value: 100,
          eventTotal:100,
          specialImportant:10,
          import:10,
          compare:10,
          common:40,
          specail:20
        },
        {
          name: "北京",
          value: 1
        },
        {
          name: "天津",
          value: 0
        },
        {
          name: "上海",
          value: 2
        },
        {
          name: "重庆",
          value: 5
        },
        {
          name: "河北",
          value: 1
        },
        {
          name: "河南",
          value: 3
        },
        {
          name: "云南",
          value: 2
        },
        {
          name: "辽宁",
          value: 1
        },
        {
          name: "黑龙江",
          value: 4
        },
        {
          name: "湖南",
          value: 0
        },
        {
          name: "安徽",
          value: 0
        },
        {
          name: "山东",
          value: 0
        },
        {
          name: "新疆",
          value: 4
        },
        {
          name: "江苏",
          value: 0
        },
        {
          name: "浙江",
          value: 0
        },
        {
          name: "江西",
          value: 0
        },
        {
          name: "湖北",
          value: 0
        },
        {
          name: "广西",
          value: 0
        },
        {
          name: "甘肃",
          value: 0
        },
        {
          name: "山西",
          value: 0
        },
        {
          name: "内蒙古",
          value: 0
        },
        {
          name: "陕西",
          value: 0
        },
        {
          name: "吉林",
          value: 0
        },
        {
          name: "福建",
          value: 0
        },
        {
          name: "贵州",
          value: 0
        },
        {
          name: "广东",
          value: 0
        },
        {
          name: "青海",
          value: 0
        },
        {
          name: "西藏",
          value: 0
        },
        {
          name: "四川",
          value: 0
        },
        {
          name: "宁夏",
          value: 4
        },
        {
          name: "海南",
          value: 0
        },
        {
          name: "台湾",
          value: 3
        },
        {
          name: "香港",
          value: 5
        },
        {
          name: "澳门",
          value: 0
        }
      ]
    };
  },
  methods: {
    //初始化中国地图
    initEchartMap() {
      let mapDiv = document.getElementById('china_map');
      let myChart = echarts.init(mapDiv);
      window.addEventListener('resize', function () {
        myChart.resize();
      });

      myChart.on('click', (params) => {
        if (params.componentType === 'series') {
          let province = params.name;
          console.log('点击了省份：', params);
          this.dataListMane.name = province
          this.dataListMane.value = params.data.value
          this.distribution=true
        }
      });

      myChart.setOption(this.options);
    },
    distrItem(){
      this.distribution=false
    },
    //修改echart配制
    setEchartOption(){
      this.options.series[0]['data'] = this.dataList;
    },
    
  },
  created() {
    this.setEchartOption();
  },
  mounted() {
    this.$nextTick(()=>{
      this.initEchartMap();
    })
    
  }
};
</script>

<style scoped>
    #china_map_box {
        height: 950px;
        position: relative;
    }
    #china_map_box #china_map{
        height: 100%;
    }
     #china_map_box .china_map_logo{
        position: absolute;
        top: 0;
        left: 0;
        width:45px;
     }

     .dis_box{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
     }
</style>
<style>
  #china_map .tooltip_style{
      /* line-height:1; */
      overflow: hidden;
      width: 30px;
      display: flex;
      /* color: aquamarine; */
  }
  #china_map .tooltip_left{
      /* float: left; */
  }
  #china_map .tooltip_right{
      /* float: right; */
  }

</style>
