import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate  from 'vuex-persistedstate' //持久化插件

Vue.use(Vuex)

const savedPowers = JSON.parse(localStorage.getItem('powers')) || [];

export default new Vuex.Store({
  state: {
    isLogin:localStorage.getItem('token') || null,
    operators: [], //厂商
    powers: savedPowers, //权限
    fuzzyQu:[], //设备列表
    
  },
  getters: {
  },
  mutations: {
    
    setLogin(state,token){
      state.isLogin=token
      localStorage.setItem('token',token)
    },
    clearToken(state) {
      state.isLogin = null;
      localStorage.removeItem('token');
    },
    setOperators(state, operators) {
      state.operators = operators;
    },
    setPowers(state, powers) {
      state.powers = powers;
      localStorage.setItem('powers', JSON.stringify(powers));
    },
    setFuzzyQuery(state, fuzzyQuery) {
      state.fuzzyQu = fuzzyQuery;
    },

  },
  actions: {
  },
  modules: {
  },
  plugins:[
    createPersistedstate({
     
      reducer(val){
         console.log(val);
        return{
          fuzzyQu:val.fuzzyQu
        }
      }
    }) 
  ]

})

