<template>
    <div class="collapse">
      <el-radio-group v-model="isCollapse" style="margin-top: 10px;">
        <el-radio-button :label="false" v-show="isCollapse"><i class="el-icon-arrow-right" ></i></el-radio-button>
        <el-radio-button :label="true" v-show="!isCollapse" style="background:#fff;width:200px;text-align:center;"><i class="el-icon-arrow-left"></i></el-radio-button>
      </el-radio-group>
      <el-menu default-active="1-4-1"  background-color="#545c64" text-color="#fff" class="el-menu-vertical-demo" unique-opened @open="handleOpen" @close="handleClose" :collapse="isCollapse" router :collapse-transition="false">
        <!-- <router-link 
          style="height: 40px; display: flex;justify-content: center;align-items: center;font-size: 22px; color: #fff; text-decoration: none"
          :style="{ color: $route.path === '/about/home' ? '#409EFF' : 'white' }"
          to="/about/home"
          >主页</router-link
        > -->
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">设备管理</span>
          </template>
          <!-- <el-menu-item-group>
            <el-menu-item index="/about/userlist">设备列表</el-menu-item>
          </el-menu-item-group> -->
          <el-menu-item-group>
            <el-menu-item index="/about/battery">电池列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span slot="title">定位管理</span>
          </template>
          <el-menu-item-group v-if="powers[2].enable">
            <el-menu-item index="/about/role">定位监控</el-menu-item>
            <!-- <el-menu-item index="/about/ListView">权限列表</el-menu-item> -->
          </el-menu-item-group>
          <el-menu-item-group v-if="powers[12].enable">
            <el-menu-item index="/about/trajectory">定位轨迹</el-menu-item>
            <!-- <el-menu-item index="/about/ListView">权限列表</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span slot="title">设备控制</span>
          </template>
          <!-- <el-menu-item-group>
            <el-menu-item index="/about/liebiao">lBms控制</el-menu-item>
          </el-menu-item-group> -->
          <el-menu-item-group >
            <el-menu-item index="/about/control">BMS控制</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/about/regulate">MCU控制</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="powers[13].enable">
            <el-menu-item index="/about/remoteControl">远程控制</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span slot="title">数据统计</span>
          </template>
          <!-- <el-menu-item-group>
            <el-menu-item index="/about/Listofgoods">统计分析</el-menu-item>
          </el-menu-item-group> -->
          <el-menu-item-group>
            <el-menu-item index="/about/statistics">统计信息</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span slot="title">账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/about/ditu">账号列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
      </el-menu>
    </div>
</template>
  <script>

  import { mapState } from 'vuex';
    export default {
      data() {
        return {
          isCollapse: true
        };
      },
      computed: {
        ...mapState(['powers']),
      },
      mounted(){
// console.log(this.powers[2].name);
      },
      methods: {
        
        handleOpen(key, keyPath) {
          console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
          console.log(key, keyPath);
        }
      }
  }
  </script>

<style lang="scss" scoped> 
   .collapse .el-radio-button__inner{
    background: #545c64;
    color:#fff;
    border:none;
    margin-bottom: 10px;
    margin-left: 2px;
    
   }

  .active {
    color: blue;
  }

  ::v-deep .el-submenu .el-menu-item  {
  font-size: 20px;
}

::v-deep .el-submenu__title * {
  font-size: 20px;
}


  .el-menu{
    border: none !important;
  }

  ::v-deep .el-radio-button__inner{
    border: none;
  }

  .el-radio-button:first-child .el-radio-button__inner{
    border: none !important;
  }
  .el-menu-vertical-demo{
    height: calc(100vh - 155px);
  }

 
</style>