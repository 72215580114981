<template>
  <div class="home">
    <div class="container">
      <div class="left">
        <div class="box">
          <pieChart></pieChart>
        </div>
        <div class="box">
          <div class="left_first">客户设备统计</div>
           <div class="first_char" style="margin:20px 0 0 0px;">
            <charDule :schedule="schedule"></charDule>
            <span>6个</span>
          </div>
          <div style="margin: -10px 0 20px 20px;color:#00CED1;font-size: 18px;">深圳隆利多光电</div>
          <div class="first_char" style="margin:30px 0 -10px 0px;padding: ;">
            <charDule :schedule="schedule"></charDule>
            <span>6个</span>
          </div>
          <div style="margin: 0 0px 20px 20px;color:#00CED1;font-size: 18px;">深圳隆利多光电</div>
        </div>
      </div>
      <div class="middle">
        <div class="box">
          <div class="left_first">设备分布</div>
          <echaTlas></echaTlas>
        </div>
      </div>
      <div class="right">
        <div class="box">
          <div class="left_first">设备概况</div>
          <div class="right_box">
            <div class="right_top">
              <div>设备总数:  <span>8个</span></div>&nbsp;&nbsp;&nbsp;&nbsp;
              <div>离线>7天:  <span>0个</span></div>
            </div>
          </div>
          <div class="right_box">
            <div class="right_top">
              <div>未处理报警:  <span>8个</span></div>
              <div>今日报警:  <span>0个</span></div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="left_first">设备平台到期概况</div>
          <rightEcha></rightEcha>
        </div>
        <div class="box">
          <div class="left_first">系统消息</div>
          <div class="right_button">
            <div>暂无消息</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import pieChart from '../components/pieChart'
import charDule from '../components/charDule'
import rightEcha from '../components/rightEcha'
import echaTlas from '../components/echaTlas'
export default {
  name: 'HomeView',
  components: {
    pieChart,
    charDule,
    rightEcha,
    echaTlas
  },
  data(){
    return {
      schedule:10,
    }
  },
  
  mounted(){
    
  },
 
}
</script>

<style lang="scss" scoped>
  
  .container {
    display: flex;
     height: calc(100vh - 150px);
  }

  .left, .middle, .right {
    display: flex;
    flex-direction: column; 
    color: transparent;
  }

  .left, .right {
    width: 25%; 
  }

  .middle {
    width: 50%; 
    display: flex;
    flex-direction: column;
  }

  .left .box, .right .box {
    flex: 1; 
    background-color: #545c64;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    
  }

  .middle .box {
    flex: 1; 
    background-color: #545c64;
    margin: 10px;
    border-radius: 5px;
  }

  .left_first{
    margin-top:10px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  .first_char{
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  
  .right_box{
    margin: 50px 0 0 3vw;
    font-size: 0.9vw;
    

    .right_top{
      // text-align: center;
      display: flex;
      
      span{
        margin-right: 2vw;
        color: #00CED1;
      }
    }
  }

  .right_button{
    text-align: center;
    margin-top: 50px;
  }
  


</style>