<template>
  <div class="home">
    <div class="progress-bar">
        <div class="progress" :style="{ width: progressBarWidth }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    schedule: Number,
  },
  components: {
    
    
  },
  data(){
    return {
    //   value: schedule,
    }
  },
  mounted(){
    
  },
  computed:{
    progressBarWidth() {
      return `${(this.schedule / 10) * 100}%`;
    },
  }
 
}
</script>

<style lang="scss" scoped>
    .progress-bar {
        width: 15vw;
        height: 40px;
        margin: 20px;
    }

    .progress {
        height: 100%;
        background-color: #3498db;
        border-radius:10px;
    }


</style>