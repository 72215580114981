import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { MessageBox } from 'element-ui';
import dataV from '@jiaminghi/data-view'
import api from '@/api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'

// import china from './utils/china' //导入china包
// echarts.registerMap('china', china) 

// Vue.prototype.$echarts = echarts

Vue.use(ElementUI);
Vue.use(dataV)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$http=api
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
