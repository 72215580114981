<template>
  <div class="HelloFlying">
    <div ref="radar" style="width:100%; height:500px;"></div>
  </div>
</template>

<script>
export default {
  name: 'pieChart',
  data(){
    return{
      
    };
  },
  props: {
    // InstrumentPane: Object,
  },
  mounted(){
    window.addEventListener('resize', this.handleResize); 
      this.drawLine();
    },
  beforeDestroy() { //销毁
    if (this.chart) {
      this.chart.dispose();
    }
  },

  methods:{
    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    drawLine() {
         // 基于准备好的dom，初始化echarts实例
        let myCharts = this.$echarts.init(this.$refs.radar);
            window.addEventListener('resize', function () {  
            // 重绘图表  
            myCharts.resize();  
        });
        const option = {
            title: {
                text: '当日报警值',
                left: 'center',
                textStyle: {
                  color: '#fff', // 设置标题字体颜色为白色
                  fontSize:25
              }
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '85%',
                left: 'center',
                textStyle: {
                  color: '#fff', // 设置图例字体颜色为白色
                  fontSize:14
              }
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data: [
                        { value: 1048, name: '电池超温' },
                        { value: 735, name: 'MOS超温' },
                        { value: 580, name: 'MOS失效' },
                        { value: 484, name: '短路保护' },
                        { value: 300, name: '放电过流' },
                        { value: 484, name: 'B-P-接反' },
                        { value: 484, name: '低电关机' },
                        { value: 484, name: '其他报警' },
                    ],
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: '#fff'
                        }
                    },
                    label: {
                      color: '#fff' ,
                      fontSize:16
                  }
                }
            ]
        };
      option && myCharts.setOption(option);
    }

  }
}
</script>

<style scoped lang="scss">
.HelloFlying{
  width: 100%;
  height: 90%;
  margin-top:10px;
}

</style>